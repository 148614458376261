@use '../../styles/colors' as colors;

.loading-spinner {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    border-top-color: #FFF !important;
    border: 4px solid rgba(0, 0, 0, 0.1);
    animation: spin 0.8s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}