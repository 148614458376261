@use '../../styles/colors' as colors;

div.action-or-feedback {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 99999;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);

    & .content {
        width: 450px;
        height: auto;
        display: flex;
        border-radius: 12px;
        flex-direction: column;
        color: colors.$black-color;
        background-color: colors.$white-color;

        @media (max-width: 460px) {
            width: 90vw;
        }

        $colors: (
            success: colors.$success-color,
            warning: colors.$warning-color,
            delete: colors.$error-color,
            error: colors.$error-color,
            info: colors.$info-color
        );

        @each $name, $color in $colors {
            & .color-#{$name} {
                color: $color;
            }

            & .background-#{$name} {
                background-color: $color;
            }
        }

        & .header {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px 12px 0 0;
        }

        & .data {
            gap: 35px;
            display: flex;
            flex-direction: column;
            padding: 15px 20px 20px 20px;
        }

        & .texts {
            gap: 10px;
            display: flex;
            user-select: none;
            flex-direction: column;

            & .title {
                font-size: 16px;
                font-weight: 600;
                text-align: center;
            }

            & .message {
                font-size: 12px;
                font-weight: 400;
                text-align: center;
                color: colors.$black-color;
            }
        }

        & .buttons {
            gap: 5px;
            display: flex;
            cursor: pointer;
            user-select: none;
            flex-direction: row;

            @media (max-width: 460px) {
                flex-direction: column-reverse;
            }

            button {
                width: 50%;
                display: flex;
                font-size: 12px;
                font-weight: 600;
                border-radius: 8px;
                padding: 10px 30px;
                justify-content: center;
                text-transform: uppercase;

                @media (max-width: 460px) {
                    width: 100%;
                }

                &#confirm {
                    color: colors.$white-color;

                    &.full-size {
                        width: 100%;
                    }
                }

                &#cancel {
                    color: colors.$black-color;
                    background-color: #DDDCDC;
                }
            }
        }
    }
}