@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* ---------- Start - Default ---------- */
* {
    margin: 0;
    border: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden !important;
}

body {
    min-height: 100vh;
    min-width: 100vw;
    overflow-x: hidden !important;
}

button:hover {
    cursor: pointer;
}

input:focus {
    outline: none;
}

li {
    list-style: none;
}