@use '../../styles/colors' as colors;

nav {
    &.navbar-collapsed-item {
        display: flex;
        flex-direction: row;

        &:hover ol.navbar-option-collapsed,
        &:hover ol.navbar-option-collapsed-expandable {
            opacity: 1;
            visibility: visible;
        }
    }

    & .navbar-icon-collapsed {
        z-index: 0;
        width: 40px;
        height: 40px;
        margin: auto;
        display: flex;
        cursor: pointer;
        position: relative;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        transition: 0.2s all ease-out;
    }

    ol.navbar-option-collapsed {
        /* Defines tooltip display */
        opacity: 0;
        display: flex;
        cursor: pointer;
        user-select: none;
        visibility: hidden;
        transition: 0.3s all ease-out;

        /* Defines tooltip position */
        left: 35px;
        position: absolute;
        align-items: center;
        justify-content: center;

        /* Defines tooltip size  */
        height: 40px;
        white-space: nowrap;
        padding: 0 20px 0 25px;
        border-radius: 0 8px 8px 0;

        /* Defines font styles */
        font-size: 14px;
        font-weight: 500;

        /* Define the colors */
        color: colors.$navbar-primary-color-hover;
        background-color: colors.$navbar-menu-color-hover;

        &:hover {
            padding: 0 20px;
            border-radius: 8px;
            transform: translateX(12px);
            color: colors.$navbar-menu-color-hover;
            background-color: colors.$navbar-primary-color-hover;
        }
    }

    ol.navbar-option-collapsed-expandable {
        /* Defines tooltip display */
        gap: 5px;
        opacity: 0;
        z-index: 5;
        display: flex;
        cursor: pointer;
        user-select: none;
        visibility: hidden;
        flex-direction: column;
        transition: 0.3s all ease-out;

        /* Defines tooltip position */
        left: 35px;
        position: absolute;

        /* Defines tooltip size  */
        height: auto;
        white-space: nowrap;
        padding: 12px 0 0 0;
        border-radius: 0 8px 8px 8px;

        /* Defines font styles */
        font-size: 14px;
        font-weight: 500;

        /* Define the colors */
        color: colors.$navbar-primary-color-hover;
        background-color: colors.$navbar-menu-color-hover;
    }

    li {
        &.navbar-submenu-item-collapsed {
            gap: 8px;
            width: 100%;
            display: flex;
            cursor: pointer;
            user-select: none;
            align-items: center;
            padding: 11px 20px 11px 25px;
            transition: 0.2s all ease-out;

            & span {
                font-size: 13px;
                font-weight: 400;
                color: colors.$navbar-primary-color;
            }

            &:hover {
                background-color: colors.$navbar-primary-color-hover;
            }

            &:hover span.navbar-text {
                color: colors.$navbar-menu-color-hover;
            }

            &:last-child:hover {
                border-radius: 0 0 8px 8px;
            }
        }

        &.navbar-options-collapsed-title {
            padding: 0 20px 6px 25px;
        }
    }

    & .navbar-collapsed-item:hover div.navbar-icon-collapsed,
    & .navbar-icon-collapsed:hover {
        background-color: colors.$navbar-menu-color-hover !important;
    }

    & .navbar-collapsed-item:hover div.navbar-icon-collapsed .navbar-icon,
    & .navbar-icon-collapsed:hover .navbar-icon {
        fill: colors.$navbar-primary-color-hover !important;
    }

    & .navbar-options-list-collapsed .navbar-icon {
        transition: 0.2s all ease-out;
    }
}