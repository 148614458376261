@use './colors' as colors;

// Defines the variables
$global-gap: 20px;

form.custom-form {
    display: flex;
    gap: 25px;
    flex-direction: column;

    & .form-section {
        gap: $global-gap;
        display: flex;
        flex-direction: column;
    }

    & .form-section-title {
        font-size: 21px;
        font-weight: 600;
        color: colors.$navbar-primary-color;
    }

    & .form-inputs {
        gap: $global-gap;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    & section {
        gap: 10px;
        display: flex;
        flex-direction: column;
    }

    [class*="field-size"] {
        min-width: 0;
    }

    & .register-button {
        display: flex;
        padding: 10px;
        font-size: 14px;
        min-height: 40px;
        font-weight: 500;
        border-radius: 6px;
        color: colors.$white-color;
        align-items: center;
        justify-content: center;
        background-color: colors.$success-color;
    }
}