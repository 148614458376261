@use '../../styles/colors' as colors;

div.modal-overlay {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 99999;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);

    & .modal-content {
        gap: 8px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-radius: 10px;
        background-color: #FFF;

        @media (max-width: 460px) {
            width: 90vw;
        }

        & .modal-header {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            & button {
                cursor: pointer;
                font-size: 12px;
                font-weight: 500;
                padding: 7px 15px;
                text-transform: uppercase;
                border-radius: 4px;
                color: #FFF;
                background-color: colors.$error-color;
            }
        }
    }
}