@use '../colors' as colors;

form.custom-form#register-service {
    max-width: 1000px;
    width: fit-content;

    section.form-buttons {
        & .loading {
            width: 20px;
            height: 20px;
            display: flex;
            border-radius: 50%;
            display: inline-block;
            border-top-color: #FFF !important;
            border: 3px solid rgba(0, 0, 0, 0.1);
            animation: spin 0.8s ease-in-out infinite;

            @keyframes spin {
                to {
                    transform: rotate(360deg);
                }
            }
        }
    }

    & .custom-field {
        &.checkbox {
            gap: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;

            & input[type="checkbox"] {
                cursor: pointer;
            }
        }

        &.service-image {
            gap: 10px;
            display: flex;
            flex-direction: column;

            & span {
                font-size: 14px;
                font-weight: 400;
                color: rgba(34, 48, 62, 0.9);
            }

            & div {
                gap: 5px;
                display: flex;
                flex-direction: column;

                & .custom-input-file {
                    width: 250px;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 10px;
                    border-radius: 8px;
                    color: #FFF;
                    background-color: #696CFF;

                    cursor: pointer;

                    display: flex;
                    justify-content: center;
                    text-transform: uppercase;

                    @media (max-width: 520px) {
                        width: 100%;
                    }
                }

                & .feedback-upload-image {
                    gap: 5px;
                    display: flex;
                    flex-direction: row;
                    font-size: 14px;
                    font-weight: 400;
                    color: colors.$success-color;

                    @media (max-width: 520px) {
                        font-size: 12px;
                        align-items: center;
                    }
                }
            }

            & .image-preview {
                display: flex;
                border-radius: 5px;
                flex-direction: row;

                & img {
                    width: 90px;
                    height: 110px;
                    border-radius: 5px;
                    background-size: cover;
                }

                & .remove-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 6px;
                    color: #FFF;
                    background-color: colors.$error-color;
                }
            }

            & input[type="file"] {
                display: none;
            }
        }
    }

    & div.form-inputs.tags {
        gap: 10px;
        flex-direction: column;
    }

    @media (max-width: 520px) {
        width: 100%;
    }
}