//
$primary-color: #5c2542;

//
$primary-color-hover: #4b0c2e;

//
$background-primary-color: #F5F5F9;

//
$white-color: #FFF;
$black-color: #292929;

//
$success-color: #11a917;
$error-color: #F04438;
$warning-color: #FFB200;
$info-color: #155BCB;

//
$error-color-hover: #bc1105;

//
$default-icon-color: #adadad;

// Navbar colors
$navbar-title: rgba(34, 48, 62, 0.5);
$navbar-primary-color: rgba(34, 48, 62, 0.9);
$navbar-primary-color-hover: #696CFF;
$navbar-menu-color-hover: #E7E7FF;
$navbar-submenu-color-hover: #EDEEF0;

//
$primary-color-for-text-field: #292929;
$primary-color-for-border-field: #CED1D5;
$primary-color-for-border-field-hover: rgb(92, 37, 66, 0.8);

//
$button-primary-color: #696CFF;
$button-primary-color-hover: #5c5efd;
$button-text-primary-color: #FFF;
$button-text-primary-color-hover: #FFF;

