@use '../../styles/colors' as colors;

.custom-field {
    display: flex;
    flex-direction: column;

    &.max-size {
        width: 100%;
    }

    & .field {
        gap: 8px;
        display: flex;
        flex-direction: column-reverse;
    }

    & label {
        font-size: 14px;
        font-weight: 400;
        color: colors.$navbar-primary-color;
    }

    @for $i from 1 through 100 {
        $size: $i * 10;

        &.size-#{$size} {
            width: #{$size}px;

            @media (max-width: 520px) {
                width: 100%;
            }
        }
    }

    & .custom-input {
        width: 100%;
        height: 40px;
        padding: 12px;
        border-radius: 4px;
        transition: 0.3s all ease-in-out;
        color: colors.$primary-color-for-text-field;
        border: 1px solid colors.$primary-color-for-border-field;

        &:focus {
            border: 1px solid colors.$primary-color-for-border-field-hover;
            filter: drop-shadow(0px 2px 12px rgba(34, 48, 62, 0.08));
        }

        &:focus~label {
            color: colors.$primary-color;
        }
    }

    & .feedback-message {
        gap: 5px;
        display: flex;
        font-size: 12px;
        font-weight: 500;
        padding: 5px 0 0 0;
        align-items: center;

        & span {
            display: flex;
        }

        & .icon {
            flex-shrink: 1;
        }

        &.error {
            color: colors.$error-color;

            & .feedback-icon {
                stroke: colors.$error-color;
            }
        }

        &.success {
            color: colors.$success-color;

            & .feedback-icon {
                stroke: colors.$success-color;
            }
        }
    }
}