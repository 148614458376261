@use '../../styles/colors' as colors;

.content-crop-image {
    gap: 10px;
    display: flex;
    flex-direction: column;

    @media (max-width: 675px) {
        width: 80vw;

        & .cropper-container.cropper-bg {
            width: 100% !important;
        }

        & .custom-cropper {
            width: 100% !important;
        }

        [data-cropper-action="se"] {
            width: 5px !important;
            height: 5px !important;
        }
    }
}

#btn-crop-image {
    width: 100%;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    padding: 7px 15px;
    text-transform: uppercase;
    border-radius: 4px;
    color: #FFF;
    background-color: #696CFF;
}