@use './colors' as colors;

// Defines the global variables
$size-expanded-grid: 260px;
$size-collapsed-grid: 80px;

section.global-content {
    width: 100%;
    height: 100vh;
    display: grid;
    transition: all 0.3s ease-in-out;
    background-color: colors.$background-primary-color;

    &.expanded {
        grid-template-columns: $size-expanded-grid 1fr;
    }

    &.collapsed {
        grid-template-columns: $size-collapsed-grid 1fr;
    }

    main#content {
        z-index: 0;
        overflow-y: auto;
        grid-column: 2 / 3;
        margin: 25px 15px 25px 30px;

        & .content-title {
            gap: 5px;
            display: flex;
            flex-direction: column;

            @media (max-width: 520px) {
                padding: 10px 20px 0 20px;
            }

            & h2 {
                font-size: 22px;
                font-weight: 600;
                user-select: none;
                color: colors.$navbar-primary-color;
            }

            & h3 {
                font-size: 14px;
                font-weight: 500;
                user-select: none;
                color: colors.$navbar-primary-color;
            }
        }

        & .content-data {
            width: fit-content;
            padding: 20px;
            border-radius: 8px;
            margin-top: 20px;
            background-color: colors.$white-color;
            filter: drop-shadow(0px 2px 6px rgba(34, 48, 62, 0.08));

            @media (max-width: 520px) {
                width: 100%;
                border-radius: 0 !important;
            }
        }
    }

    @media (max-width: 520px) {
        &.collapsed {
            grid-template-rows: $size-collapsed-grid 1fr;

            main#content {
                margin: 0;
                grid-column: 1 / 3;
                padding: 0 !important;
            }
        }

        &.expanded {
            grid-template-columns: 90% 1fr;

            main#content {
                display: none;
            }
        }
    }

    section#navbar {
        height: 100vh;
        grid-column: 1 / 2;
        background-color: colors.$white-color;
        transition: all 0.3s ease-in-out;
        filter: drop-shadow(0px 2px 6px rgba(34, 48, 62, 0.08));

        &.expanded {
            width: $size-expanded-grid;
        }

        &.collapsed {
            width: 80px;
        }

        @media (max-width: 520px) {
            display: flex;
            align-items: center;
            justify-content: center;

            &.expanded {
                width: 100%;

                #change-navbar {
                    left: calc(100% - 17px);
                    position: absolute;
                }
            }

            &.collapsed {
                width: 60px;
                height: 60px;
                margin: 15px;
                border-radius: 12px;

                #change-navbar {
                    top: 0;
                    left: 0;
                    width: 35px;
                    height: 35px;
                    display: flex;
                    position: relative;
                }
            }

            .content-navbar-collapsed {
                display: none;
            }
        }
    }
}