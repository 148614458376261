@use '../colors' as colors;

// Defines the variables
$margin-right: 20px;
$table-padding: 12px 15px;
$table-padding-first-child: 12px 25px;

main#content {
    margin: 0 !important;
    padding: 25px 30px !important;

    & .data-for-list-services {
        width: 100% !important;
        padding: 20px 0 !important;
    }

    section#list-services {
        gap: 15px;
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        & .custom-icon {
            fill: colors.$black-color;
        }

        & button#register-service {
            padding: 10px 40px;
            font-size: 14px;
            font-weight: 400;
            border-radius: 8px;
            margin-right: $margin-right;
            transition: 0.2s all ease-in-out;
            color: colors.$button-text-primary-color;
            background-color: colors.$button-primary-color;

            &:hover {
                color: colors.$button-text-primary-color-hover;
                background-color: colors.$button-primary-color-hover;
            }
        }

        & .about-registered-services {
            font-size: 12px;
            font-weight: 400;
            color: colors.$black-color;
            margin-right: $margin-right;
        }

        & table {
            width: 100%;
            border-collapse: collapse;
        }

        & thead {
            border-top: 1px solid #E4E6E8;
            border-bottom: 1px solid #E4E6E8;

            & th {
                font-size: 13px;
                font-weight: 600;
                color: colors.$black-color;
                text-align: justify;
                padding: $table-padding;
                text-transform: uppercase;

                &:first-child {
                    padding: $table-padding-first-child;
                }
            }
        }

        & tbody {
            & tr {
                &:nth-child(odd) {
                    background-color: #f5f5f5;
                }
            }

            & td {
                font-size: 13px;
                font-weight: 500;
                color: colors.$black-color;
                padding: $table-padding;

                &:first-child {
                    padding: $table-padding-first-child;
                }
            }

            & .table-actions {
                gap: 10px;
                display: flex;
                cursor: pointer;
            }
        }

        @media (max-width: 520px) {
            // responsive code
        }
    }
}