@use '../../styles/colors' as colors;

.sign-message {
    gap: 5px;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    padding: 6px 10px 6px 10px;

    & span {
        display: flex;
    }

    & .icon {
        flex-shrink: 1;
    }

    &.error {
        color: colors.$error-color;
    }

    &.success {
        color: colors.$success-color;
    }
}