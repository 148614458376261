@use '../colors' as colors;

section.sign {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row-reverse;

    & .content {
        gap: 50px;
        width: 25%;
        height: 100%;
        display: flex;
        min-width: 400px;
        flex-direction: column;
        padding: 8vh 4vw 8vh 4vw;
    }

    & .content-image {
        width: 75%;
        height: 100%;
        background: url('../../images/wallpaper.png') no-repeat center/cover;
    }

    & .logo {
        width: 40px;
        height: 65px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../images/icon.png');
    }

    @media (max-width: 700px) {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;

        & .content {
            gap: 0;
            width: 100vw;
            min-width: 100vw;
            padding: 5vh 5vw 8vh 5vw;
        }

        & .content-image {
            width: 100vw;
            height: 30vw;
            background-position: center;
            background-size: cover;
        }
    }

    form {
        & .form-buttons {
            gap: 30px;
            display: flex;
            flex-wrap: wrap;
            padding-top: 12px;
            justify-content: flex-end;
        }

        & .sign-link {
            display: flex;
            font-size: 12px;
            font-weight: 500;
            text-decoration: none;
            color: colors.$primary-color;
            transition: color 0.4s ease-in-out;
        }

        & .sign-btn {
            width: 100%;
            height: 50px;
            display: flex;
            font-size: 14px;
            align-items: center;
            border-radius: 10px;
            color: colors.$white-color;
            justify-content: center;
            text-transform: uppercase;
            transition: background 0.4s ease-in-out;
            background-color: colors.$primary-color;

            &:hover {
                background-color: colors.$primary-color-hover;
            }
        }
    }
}