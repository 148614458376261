@use '../../styles/colors' as colors;

form {
    & .form-input {
        width: 100%;
        margin-top: 20px;
        position: relative;
        border-bottom: 2px solid #d9d9d9;

        &:focus-within {
            .icon-focus {
                color: colors.$primary-color-hover;
            }
        }
    }

    & .input-data {
        width: 100%;
        height: 55px;
        display: block;
        font-size: 14px;
        line-height: 1.2;
        background: transparent;
        color: colors.$black-color;

        & [name="password"] {
            width: 80%;
        }
    }

    & .input-title {
        font-size: 14px;
        line-height: 1.5;
        padding-left: 7px;
        color: colors.$black-color;
    }

    & .input-wrapper {
        gap: 8px;
        display: flex;
        padding: 0 10px 0 10px;
    }

    & .show-and-hide-password {
        display: block;
        margin-top: 1.5vh;
        position: absolute;
        color: colors.$default-icon-color;
        background-color: transparent;
        margin-left: calc(100% - 35px);
        transition: color 0.4s ease-in-out;

        &:hover {
            cursor: pointer;
            color: colors.$primary-color !important;
        }
    }

    & .input-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .icon-focus {
        color: colors.$default-icon-color;
    }

    .input-focus {
        &::before {
            left: 0;
            width: 0;
            height: 2px;
            content: "";
            bottom: -2px;
            display: block;
            position: absolute;
            transition: all 0.4s;
            -o-transition: all 0.4s;
            -moz-transition: all 0.4s;
            -webkit-transition: all 0.4s;
            background: colors.$primary-color;
        }
    }

    .input-data {
        &:focus+.input-focus::before,
        &.has-val+.input-focus::before {
            width: 100%;
        }

        &:focus+.input-focus::after,
        &.has-val+.input-focus::after {
            color: colors.$primary-color;
        }
    }
}