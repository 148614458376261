@use '../../styles/colors' as colors;

.feedback-overlay {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 99999;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);

    & .feedback {
        width: 380px;
        height: auto;
        display: flex;
        border-radius: 12px;
        flex-direction: column;
        color: colors.$black-color;
        background-color: colors.$white-color;

        @media (max-width: 430px) {
            width: 90vw;
        }

        $colors: (
            success: colors.$success-color,
            warning: colors.$warning-color,
            error: colors.$error-color,
            info: colors.$info-color
        );

        @each $name, $color in $colors {
            & .color-#{$name} {
                color: $color;
            }

            & .background-#{$name} {
                background-color: $color;
            }
        }
    }

    & .feedback-content {
        gap: 35px;
        display: flex;
        flex-direction: column;
        padding: 15px 20px 20px 20px;
    }

    & .feedback-header {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px 12px 0 0;
    }

    & .feedback-title {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }

    & .feedback-text {
        gap: 10px;
        display: flex;
        user-select: none;
        flex-direction: column;
    }

    & .feedback-message {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: colors.$black-color;
    }

    & .feedback-close {
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        border-radius: 8px;
        text-transform: uppercase;
        padding: 10px 30px 10px 30px;
        color: colors.$white-color;
    }

    & .feedback-buttons {
        gap: 5px;
        display: flex;
        cursor: pointer;
        user-select: none;
        flex-direction: column;
    }
}