@use '../colors' as colors;

.custom-button {
    width: 200px;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    margin-top: 25px;
    padding: 5px 25px;
    border-radius: 8px;

    color: colors.$button-text-primary-color;
    background-color: colors.$button-primary-color;
}

.loading {
    width: 20px;
    height: 20px;
    display: flex;
    border-radius: 50%;
    display: inline-block;
    border-top-color: #FFF !important;
    border: 3px solid rgba(0, 0, 0, 0.1);
    animation: spin 0.8s ease-in-out infinite;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}

.times-options {
    gap: 10px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
}

.custom-radio {
    display: flex;
    align-items: center;

    .custom-check {
        width: 67.7px;
        display: flex;
        padding: 8px 0;
        font-size: 14px;
        font-weight: 600;

        cursor: pointer;

        text-align: center;
        justify-content: center;

        border-radius: 8px;
        background-color: #EDEEF0;
        transition: 0.2s all ease-in-out;

        &:hover {
            color: #696CFF;
            background-color: #E7E7FF;
        }
    }

    .custom-input-radio:checked+.custom-check {
        color: #696CFF;
        background-color: #E7E7FF;
    }

    .custom-input-radio {
        display: none;
    }
}

.feedback-times-options {
    gap: 10px;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    padding: 14px;
    border-radius: 8px;

    color: colors.$error-color;
    background-color: rgb(255, 235, 235);

    & .icon {
        flex-shrink: 1;
        stroke: colors.$error-color;
    }
}