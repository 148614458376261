@use '../../styles/colors' as colors;

/* *********************** */
section#navbar {
    z-index: 99999;

    /* navbar position */
    & .content-navbar {
        gap: 40px;
        width: 100%;
        height: 100%;
        display: flex;
        padding: 30px 0 30px 20px;
        flex-direction: column;
        justify-content: space-between;
    }

    & .content-navbar-collapsed {
        gap: 40px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 30px 0 30px 0;
        justify-content: space-between;
    }

    /* logomarca */
    header#navbar-logo {
        cursor: pointer;

        & div {
            background-size: contain;
            background-repeat: no-repeat;

            &.logo {
                height: 50px;
                margin-left: 8px;
                background-image: url('../../images/logo.png');
            }

            &.logo-collapsed {
                width: 33px;
                height: 50px;
                background-image: url('../../images/logo-collapsed.png');
            }
        }
    }

    /* footer data */
    & footer#navbar-footer {
        gap: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px 0 0;
        justify-content: space-between;

        & .navbar-user-data {
            gap: 7px;
            display: flex;
            user-select: none;
            align-items: center;
            flex-direction: row;
        }

        & .user-photo {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            border: 2px solid rgba(233, 236, 239, 0.4);
            background-image: url('../../images/avatar.png');
        }

        & .user-info {
            gap: 3px;
            display: flex;
            flex-direction: column;
        }

        & .user-name {
            font-size: 15px;
            font-weight: 600;
            color: colors.$navbar-primary-color;
        }

        & .user-position {
            font-size: 11px;
            font-weight: 400;
            color: colors.$navbar-primary-color;
        }

        & #logout {
            width: 26px;
            height: 24px;
            cursor: pointer;

            & .navbar-icon {
                fill: colors.$error-color;
                transition: all 0.3s ease-in-out;
            }

            &:hover .navbar-icon {
                fill: colors.$error-color-hover;
                transform: translateX(1px);
            }
        }
    }

    /* custom navbar */
    nav#global-navbar {
        gap: 40px;
        display: flex;
        flex-direction: column;

        &.global-navbar-collapsed {
            align-items: center;
        }

        nav {
            &.navbar-options {
                display: flex;
                overflow-x: hidden;
                overflow-y: hidden;
                padding: 0 20px 0 0;
                flex-direction: column;
                height: calc(100vh - 230px);

                &:hover {
                    overflow-y: visible;
                }

                &::-webkit-scrollbar-thumb {
                    cursor: pointer;
                    border-radius: 50px;
                    background-color: colors.$navbar-primary-color;
                }

                &::-webkit-scrollbar {
                    width: 4px;
                }
            }

            &.navbar-options-collapsed {
                display: flex;
                overflow-x: hidden;
                overflow-y: hidden;
                align-items: center;
                flex-direction: column;
                height: calc(100vh - 230px);

                &:hover {
                    width: 100vw;
                    overflow-y: visible;
                }

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            & .navbar-options-list {
                gap: 20px;
                display: flex;
                flex-direction: column;
            }

            & .navbar-options-list-collapsed {
                gap: 5px;
                display: flex;
                position: relative;
                flex-direction: column;
            }

            h4 {
                &.navbar-title {
                    margin-left: 8px;
                    font-weight: 400;
                    font-size: 11.5px;
                    user-select: none;
                    padding-bottom: 5px;
                    color: colors.$navbar-title;
                    text-transform: uppercase;
                }
            }
        }
    }

    /* change navbar button */
    & #change-navbar {
        z-index: 3;
        top: 25px;
        position: absolute;
        background-color: transparent;
        transition: all 0.3s ease-in-out;

        &.expanded {
            left: 242px;
        }

        &.collapsed {
            left: 62px;
        }

        &:hover .icon-change-navbar {
            fill: colors.$navbar-primary-color-hover;
        }

        & .icon-change-navbar {
            fill: colors.$navbar-primary-color;
            transition: all 0.3s ease-in-out;
        }
    }

    /* custom navbar item */
    & .navbar-item {
        & span {
            font-size: 14px;
            font-weight: 400;
            color: colors.$navbar-primary-color;
        }

        &:hover {
            margin: auto;
            border-radius: 8px;
            transform: translateX(5px);
            background-color: colors.$navbar-menu-color-hover;
        }
    }

    & .navbar-submenu-item {
        & span {
            font-size: 13px;
            font-weight: 400;
            color: colors.$navbar-primary-color;
        }

        &:hover {
            border-radius: 8px;
            transform: translateX(2px);
            background-color: colors.$navbar-submenu-color-hover;
        }

        &:hover .navbar-submenu-icon {
            stroke-width: 0.5px;
            stroke: colors.$white-color;
        }
    }

    & .navbar-item,
    & .navbar-submenu-item {
        gap: 8px;
        display: flex;
        cursor: pointer;
        user-select: none;
        padding: 10px 8px;
        align-items: center;
        transition: 0.2s all ease-out;
    }

    & .navbar-item:hover path.navbar-icon,
    & .navbar-item:hover .navbar-text {
        font-weight: 500;
        fill: colors.$navbar-primary-color-hover;
        color: colors.$navbar-primary-color-hover;
    }

    & .navbar-item {
        &.expandable {
            display: flex;
            flex-direction: column;
        }
    }

    & .navbar-icon {
        fill: colors.$navbar-primary-color;
    }

    & .navbar-expandable {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    & .navbar-expandable-item {
        gap: 8px;
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    & .navbar-submenu {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
    }
}